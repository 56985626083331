export const sleep = (time: number) => new Promise((res) => setTimeout(res, time));

export function formatCurrency(amountInKopecks: number | undefined): string {
    if (amountInKopecks === undefined) {
        return "0.00 ₽";
    }
    // Определяем знак числа
    const isNegative = amountInKopecks < 0;
    const absoluteAmount = Math.abs(amountInKopecks);

    // Переводим копейки в рубли и отдельно выделяем остаток (копейки)
    const rubles = Math.floor(absoluteAmount / 100);
    const kopecks = absoluteAmount % 100;

    // Форматируем рубли с разделением тысяч
    const rublesFormatted = rubles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Возвращаем строку в нужном формате, включая знак, если число отрицательное
    return `${isNegative ? '-' : ''}${rublesFormatted}.${kopecks.toString().padStart(2, '0')} ₽`;

}


export const calculateWindowSize = (windowWidth: number) => {
    if (windowWidth >= 1200) {
        return 'lg';
    }
    if (windowWidth >= 992) {
        return 'md';
    }
    if (windowWidth >= 768) {
        return 'sm';
    }
    return 'xs';
};

export const addWindowClass = (classList: string) => {
    const window: HTMLElement | null =
        document && document.getElementById('root');
    if (window) {
        // @ts-ignore
        window.classList.add(classList);
    }
};

export const removeWindowClass = (classList: string) => {
    const window: HTMLElement | null =
        document && document.getElementById('root');
    if (window) {
        // @ts-ignore
        window.classList.remove(classList);
    }
};


//JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')