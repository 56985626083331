/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {RolesModel} from "./_model";
import {Pagination} from "../../crud/types/Pagination";

class RolesIndex extends BaseIndexPage {
    //public model = RolesModel;

   /* constructor(props: any) {
        super(props);
        super.model = RolesModel;
        super.defaultShowAttrs = RolesModel.defaultShowAttrs;
        super.relationTree = RolesModel.relationTree
    }*/

    constructor(props: any) {
        super(props);
        super.model = RolesModel;
        super.defaultShowAttrs = RolesModel.defaultShowAttrs;
        super.relationTree = RolesModel.relationTree
        this.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "level", //id,-id, someField
            searchArray: {}, //id=2&someField=someValue&
            showAttr: {},
        };
        this.state.data.pagination.pageSize = 15;
    }
}

export default RolesIndex;