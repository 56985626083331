import {toast} from "react-toastify";
import React from "react";
import {formatCurrency} from "../../utils/helpers";
import UserProfileView from "../../pages/users/UserProfileView";

export function onPayment(resp: any) {
    toast.info(
        <div>
            <UserProfileView
                id={resp?.payment?.user?.id}
                name={resp?.payment?.user?.name}
                image_name={resp?.payment?.user?.image_name}
            />
            {resp?.payment?.descr}<br/>
            <b>{formatCurrency(resp.payment.sum)}</b><br/>
        </div>
    );
}

