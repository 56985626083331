import React, {Component} from "react";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Link, Navigate} from "react-router-dom";
import DataTable from "../data_viewers/DataTable";
import {Button, Card, Col, Row} from "react-bootstrap";
import {Translation} from 'react-i18next';
import {DeleteModel, GetModel} from "../api/crudApi";
import {toast} from "react-toastify";
import {BaseModelInfo, defaultModel, IRelationTree} from "../types/BaseModelInfo";
import TableLoader from "../../ui/loaders/TableLoader";
import {BaseDeleteModal} from "../dialogs/BaseDeleteModal";

export interface IBaseViewState {
    data: any,
    isDataLoaded: boolean,
    hasError: boolean
    redirect: boolean
    modal: boolean,
    modelToDelete?: number,
    sortBy?: string, //todo?
}

class BaseViewPage extends Component<any, IBaseViewState> {
    public model: BaseModelInfo = defaultModel;

    public disabledAttr: Array<string> = [];

    public relationTree: IRelationTree = {}

    constructor(props: any) {
        super(props);

        this.state = {
            data: [],
            isDataLoaded: false,
            modal: false,
            hasError: false,
            redirect: false
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        GetModel(this.model.info.apiPath, id, this.relationTree)
            .then((resp: any) => {
                console.log(resp)
                if (resp.data) {
                    this.setState({data: resp.data});
                    this.setState({isDataLoaded: true});
                } else {
                    this.setState({hasError: true})
                }

                breadcrumbsStore.items = [
                    {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
                    {name: this.model.info.label, link: this.model.info.viewPath},
                    {name: resp.data.name ?? '#' + resp.data.id},
                ]
                this.setState({isDataLoaded: true});
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));

    }

    deleteModel = async () => {
        if (this.state.modelToDelete)
            DeleteModel(this.model.info.apiPath.toLowerCase(), this.state.modelToDelete)
                .then((resp: any) => {
                    console.log(resp)
                    this.setState({redirect: true});
                    toast.success("Запись удалена")
                })
                .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    closeModal = () => {
        this.setState({modal: false});
        this.setState({modelToDelete: undefined});
    }

    openModal = (id: number) => {
        this.setState({modelToDelete: id});
        this.setState({modal: true});
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath}/>;
        }

        return (this.state.isDataLoaded)
            ? <Card className="card-outline card-success">
                <BaseDeleteModal show={this.state.modal} onDelete={this.deleteModel} onClose={this.closeModal}/>
                <Card.Header>
                    <Row className="justify-content-between">
                        <Col><h1>{this.state.data?.name ?? "#" + this.state.data?.id}</h1></Col>
                        <Col>
                            <div className="float-right">
                                <Link className="btn btn-primary"
                                      to={this.model.info.viewPath + '/update/' + this.state.data.id}>
                                    <Translation>{(t: any) => t('crud.update')}</Translation>
                                </Link>
                                <Button variant="danger" className="ml-2"
                                        onClick={() => this.openModal(this.state.data.id)}>
                                    <Translation>{(t: any) => t('crud.delete')}</Translation>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        data={this.state.data}
                        model={this.model}
                        disabledFields={this.disabledAttr}
                    />
                </Card.Body>
            </Card> : <TableLoader/>;
    }
}

export default (BaseViewPage);
