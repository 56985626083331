import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {KassaCheckout, KassaGetCheckpointId} from "../../../api/kassa/kassa";
import {toast} from "react-toastify";
import {checkoutStore, checkpointTreeJson} from "../../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import MomentViewer from "../../../crud/field_viewers/MomentViewer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {PaymentTypeValues} from "../../../models/kassa/PaymentTypeValues";
import UserProfileView from "../../users/UserProfileView";
import {SwitchField} from "../../../crud";
import {EventsModel} from "../../table-games/events/_model";
import EventOneDropDown from "../../../crud/FormInputs/custom/EventOneDropDown";
import {CheckpointUser} from "../../../models/users/UserCheckpoint";
import {getPaymentType} from "../helpers";
import {formatCurrency} from "../../../utils/helpers";

type Props = {
    checkpoint: CheckpointUser,
    checkIn: boolean
}
export const CheckpointModal = observer((props: Props) => {
    const [countdown, setCountdown] = useState(10);

    const jitterTimerRef = useRef<any>(null);
    const [jitter, setJitter] = useState(0);

    const [descr, setDescr] = useState(props.checkpoint.descr);
    const [checkpoint, setCheckpoint] = useState(props.checkpoint);
    const hasStop = (): boolean => {
        if (checkpoint?.tarif?.stop_check_price) {
            return (checkpoint?.sum ?? 0) > (checkpoint?.tarif?.stop_check_price ?? 0);
        }
        return false
    }

    useEffect(() => {
        jitterTimerRef.current = setInterval(() => setJitter(prevJitter => prevJitter + 1), 1000);
        return () => {
            clearInterval(jitterTimerRef.current);
        };
    }, []);


    if (props.checkIn) {
        useEffect(() => {
            if (countdown > 0) {
                const timer = setTimeout(() => {
                    setCountdown(countdown - 1);
                }, 1000);

                return () => clearTimeout(timer);
            } else if (countdown === 0) {
                onClose(checkpoint.id);
            }
        }, [countdown]);
    }

    const onClose = (checkpointId: number) => {
        // checkpoint = null;
        if (props.checkIn) {
            checkoutStore.removeCurrentCheckInPoint(checkpointId);
        } else {
            checkoutStore.removeCurrentCheckpoint(checkpointId)

        }
        clearInterval(jitterTimerRef.current);
        checkoutStore.getData()
        checkoutStore.reCalcCurIndex();
    }

    // const checkout = (id: number, type: keyof typeof CheckoutType) => {
    const checkout = (id: number, type: keyof typeof PaymentTypeValues, jitter: number) => {
        KassaCheckout(id, PaymentTypeValues[type], jitter)

            //KassaCheckout(id, type)
            .then((resp: any) => {
                console.log(resp.data)
                //setCurrentUsers(resp.data);
                //
                //checkoutStore.currentInvoice = resp.data
                toast.info(
                    <>Создан чек #{resp.data.id} <br/>
                        {getPaymentType(resp.data.payment_type)} на сумму {formatCurrency(resp.data.sum)}
                    </>);
                onClose(id);
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    //if(props.checkpoint.)
    return <div>
        {/*   <Modal.Header closeButton>
            <Modal.Title>Посетитель #{checkpoint?.user?.name}</Modal.Title>
        </Modal.Header>*/}
        <div>

            <table className="table table-sm">
                <tbody>
                {
                    /*<tr>
                    <td>Карта</td>
                    <td>{checkpoint?.user?.barcode}</td>
                </tr>
                */
                }
                <tr>
                    <td colSpan={2}>
                        <UserProfileView id={checkpoint?.user?.id}
                                         image_name={checkpoint?.user?.image_name}
                                         name={checkpoint?.user?.name}
                                         role={checkpoint?.user?.role?.name}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Роль</td>
                    <td>{checkpoint?.user?.role?.name}</td>
                </tr>
                <tr>
                    <td>Баланс</td>
                    <td>{formatCurrency(checkpoint?.user?.balance)}</td>
                </tr>
                <tr>
                    <td>Тариф</td>
                    <td>{checkpoint?.tarif.name}</td>
                </tr>
                <tr>
                    <td>Время прихода</td>
                    <td><MomentViewer value={checkpoint?.created_at}/></td>
                </tr>
                <tr>
                    <td>Времени провел</td>
                    <td>
                        <>{checkpoint?.minutes} мин</>
                        <small> + {jitter} сек</small>
                        <button type="button"
                                onClick={
                                    async () => {
                                        let resp = await KassaGetCheckpointId(checkpoint.id.toString(), checkpointTreeJson);
                                        setCheckpoint(resp.data);
                                        setJitter(0)
                                        //checkoutStore.updateCheckpoint(props.checkpoint.id.toString()).then(() => {
                                        //    clearTimeout(jitterTimer)
                                        //    setJitter(0)
                                        //jitterTimer = setTimeout(() => setJitter(jitter + 1), 1000);
                                        // })

                                    }
                                }
                                className="ml-1 btn btn-outline-info btn-sm">
                            <FontAwesomeIcon icon={faRefresh}/>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>Итого</td>
                    {
                        (hasStop())
                            ? <td>
                                <b><span className="text-success">
                                            {formatCurrency(checkpoint?.tarif?.stop_check_price)}
                                        </span> </b>/
                                {formatCurrency(checkpoint?.sum)}

                            </td>
                            : <td>
                                <b>{formatCurrency(checkpoint?.sum)}</b>
                                <button type="button"
                                        onClick={() => toast.warning("Корректировка суммы не доступна!")}
                                        className="ml-1 btn btn-outline-info btn-sm">
                                    <FontAwesomeIcon icon={faPencil}/>
                                </button>
                            </td>
                    }
                </tr>
                </tbody>
            </table>

            <Row>
                <Col>
                    <EventOneDropDown name={'eventTypes_id'}
                                      model={EventsModel.info.name}
                                      label="Мероприятие"
                                      relationUrl={EventsModel?.info?.apiPath + "/index?search=%7B%22is_enabled%22:%221%22%7D"}
                        /*    onChange={(val: any) => this.handleInputChange({
                                name: "event_type_id",
                                value: val.id
                            })}*/
                                      value={null} onChange={(e: any) => {
                        console.log(e)
                    }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                             <textarea
                                 autoFocus
                                 rows={2}
                                 placeholder="Коментарий к этому посещению..."
                                 //tabIndex="0"
                                 name="descr"
                                 className="form-control"
                                 //value={props?.checkpoint?.descr}
                                 value={descr}
                                 //onChange={e => handleTextareaChange(e)}
                                 //onKeyDown={e => handleTextareaKeyDown(e)}
                                 //onFocus={e => handleTextareaFocus(e)}
                                 //value={inputText}
                                 //ref={textAreaRef}
                                 onChange={(e: any) => {
                                     console.log(e?.target?.value);
                                     setDescr(e?.target?.value);
                                     checkoutStore.updateCheckpointField(
                                         {
                                             name: "descr",
                                             value: e?.target?.value,
                                         }, checkpoint.id);

                                 }}
                             />
                </Col>
            </Row>
            <Row className="pt-1">
                <Col>
                    <SwitchField
                        name="is_prepayment"
                        model="is_prepayment"
                        value={checkpoint?.is_prepayment ?? false}
                        label="Предоплата"
                        onChange={(e: any) => {
                            checkoutStore.updateCheckpointField(e, checkpoint.id);
                            checkpoint.is_prepayment = e?.value ?? false;
                            setCheckpoint(checkpoint);
                        }}/>
                </Col>
                <Col>
                    <SwitchField
                        name="is_first_time"
                        model="is_first_time"
                        value={checkpoint?.is_first_time ?? false}
                        label="Перворазник"
                        onChange={(e: any) => {
                            checkoutStore.updateCheckpointField(e, checkpoint.id);
                            checkpoint.is_first_time = e?.value ?? false;
                            setCheckpoint(checkpoint);
                        }}/>
                </Col>
                <Col>
                    <SwitchField
                        name="is_flyer_invited"
                        model="is_flyer_invited"
                        value={checkpoint?.is_flyer_invited ?? false}
                        label="По флаеру"
                        onChange={(e: any) => {
                            checkoutStore.updateCheckpointField(e, checkpoint.id);
                            checkpoint.is_flyer_invited = e?.value ?? false;
                            setCheckpoint(checkpoint);
                        }}/>
                </Col>
                <Col>
                    <SwitchField
                        name="is_reserved"
                        model="is_reserved"
                        value={checkpoint?.is_reserved ?? false}
                        label="На резевр"
                        onChange={(e: any) => {
                            checkoutStore.updateCheckpointField(e, checkpoint.id);
                            checkpoint.is_reserved = e?.value ?? false;
                            setCheckpoint(checkpoint);
                        }}/>
                </Col>
                <Col>
                    <SwitchField
                        name="is_help"
                        model="is_help"
                        value={checkpoint?.is_help ?? false}
                        label="Помогал в смене"
                        onChange={(e: any) => {
                            checkoutStore.updateCheckpointField(e, checkpoint.id);
                            checkpoint.is_help = e?.value ?? false;
                            setCheckpoint(checkpoint);
                        }}/>
                </Col>


            </Row>


        </div>
        <Row>
            <Col>
                <button type="button" className="btn btn-outline-secondary"
                        onClick={() => onClose(checkpoint.id)} data-dismiss="modal">
                    Продолжить {props.checkIn && "(" + countdown + ")"}
                </button>

            </Col>

            <Col>
                <button type="button"
                        onClick={() => checkout(checkpoint!.id, "cash", jitter)}
                        className="btn btn-outline-success ">
                    <FontAwesomeIcon icon={solid("money-bill-1")}/> Нал
                    ({hasStop() ? formatCurrency(checkpoint?.tarif.stop_check_price) : formatCurrency(checkpoint?.sum)})
                </button>
                {<button type="button"
                         onClick={() => checkout(checkpoint!.id, "qr", jitter)}
                         className="btn btn-outline-warning">
                    <FontAwesomeIcon icon={solid("qrcode")}/> По QR
                    ({hasStop() ? formatCurrency(checkpoint?.tarif.stop_check_price) : formatCurrency(checkpoint?.sum)})
                </button>
                }
                {<button type="button"
                         onClick={() => checkout(checkpoint!.id, "card", jitter)}
                         className="btn btn-outline-primary">
                    <FontAwesomeIcon icon={solid("credit-card")}/> По карте ({formatCurrency(checkpoint?.sum)})
                </button>
                }

            </Col>
            <Col>
                <button type="button"
                        onClick={() => checkout(checkpoint!.id, "deposit", jitter)}
                        className="btn btn-info">
                    <FontAwesomeIcon icon={solid("piggy-bank")}/>Депозит
                    ({hasStop() ? formatCurrency(checkpoint?.tarif.stop_check_price) : formatCurrency(checkpoint?.sum)})
                </button>

                {<button type="button"
                         onClick={() => checkout(props.checkpoint!.id, "qr_spb", jitter)}
                         className="btn btn-primary disabled">
                    <FontAwesomeIcon icon={solid("qrcode")}/>Бот
                    ({hasStop() ? formatCurrency(checkpoint?.tarif.stop_check_price) : formatCurrency(checkpoint?.sum)})
                </button>
                }
            </Col>
            <Col>
                <button type="button" className="btn btn-danger"
                        onClick={() => {
                            checkout(checkpoint!.id, "reseted", jitter);
                            onClose(checkpoint.id)
                        }} data-dismiss="modal">
                    Туй
                </button>
            </Col>

        </Row>
        <div>


            <div className="justify-content-between text-center">

            </div>


            {/* <button type="button" className="btn btn-outline-warning  btn-lg">Перевод (798руб)</button>*/}
        </div>
    </div>
})
