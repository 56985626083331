import React from 'react';
import {CheckpointUser} from "../../../models/users/UserCheckpoint";
import {PaymentType} from "../../../models/kassa/PaymentType";
import {formatCurrency} from "../../../utils/helpers";

type  Props = {
    checkpoints: Array<CheckpointUser>
}
export const FinanceReport = (props: Props) => {
    //const [checkpoints, setCheckpoints] = useState<Array<CheckpointUser>>([]);
    //const [pagination, setPagination] = useState<Pagination>(new Pagination());
    /*
        const attrs = {
            id: new AttrInfo("id", "ID"),
            users: new AttrInfo("users", "Пользователь", FieldType.relationOneData, {name: "fio"}),
            created_at: new AttrInfo("created_at", "Дата входа", FieldType.custom, (val: any) => moment(val).calendar()),
            outside_at: new AttrInfo("outside_at", "Дата выхода", FieldType.custom, (val: any) => moment(val).calendar()),
            minutes: new AttrInfo("minutes", "Времени провел", FieldType.custom, (val: any) => <span>{val}мин</span>),
            roles: new AttrInfo("users", "Роль", FieldType.custom, (val: any) => <span>{val?.roles?.name}</span>),
            sum: new AttrInfo("sum", "Сумма", FieldType.custom, (val: any) => <PriceWidget value={val}/>),
            status: new AttrInfo(null, "Статус", FieldType.custom, (val: any) => <b>{getCheckpointStatus(val)} {getPayStatus(val)} </b>),
            updated_at: new AttrInfo("updated_at", "Дата обновления"),
        };
    */
    //useEffect(() => getData(), [])
    let totalSum = 0;
    const paymentTypeSums: { [key in PaymentType]: number } = {
        "-1": 0,
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
    };


    props.checkpoints?.forEach((checkpointUser) => {
        //console.log("forEach in checkpoints #", checkpointUser)
        checkpointUser.userInvoices?.forEach((userInvoice) => {
            //console.log(" - forEach in userInvoices #", userInvoice)
            userInvoice.userPayments?.forEach((userPayment) => {
                //console.log(" - - forEach in userPayment #", userPayment)
                const paymentType = userPayment.payment_type;
                const paymentSum = userPayment.sum;
                totalSum += userPayment.sum;
                paymentTypeSums[paymentType] += paymentSum;
            });
        });
    });
    //console.log(paymentTypeSums);
//  "reseted": -1,
//     "cash": 0,
//     "card": 1,
//     "deposit": 2,
//     "qr": 3,
//     "qr_spb": 4
    return (
        <div>
            <table className="table table-sm">
                <tbody>
                <tr>
                    <td>Отмены</td>
                    <td>{formatCurrency(paymentTypeSums[-1])}</td>
                </tr>
                <tr>
                    <td>Наличные</td>
                    <td>{formatCurrency(paymentTypeSums[0])}</td>
                </tr>
                <tr>
                    <td>По карте</td>
                    <td>{formatCurrency(paymentTypeSums[1])}</td>
                </tr>
                <tr>
                    <td>По QR</td>
                    <td>{formatCurrency(paymentTypeSums[3])}</td>
                </tr>
                <tr>
                    <td>Депозиты</td>
                    <td>{formatCurrency(paymentTypeSums[2])}</td>
                </tr>
                <tr>
                    <td>QR SBP через бота</td>
                    <td>{formatCurrency(paymentTypeSums[4])}</td>
                </tr>
                <tr>
                    <td>QR SBP через бота</td>
                    <td>{formatCurrency(paymentTypeSums[5])}</td>
                </tr>
                </tbody>
            </table>
            <span className="float-right">Всего: <b>{formatCurrency(totalSum)}</b></span>
        </div>

    )
}
